
import YCX_Request from "./request";
import { TIME_OUT,BASE_URL,NODE_BASE_URL } from "./request/config";
import localCache from '@/utils/cache'

const ycx_Request = new YCX_Request({
  baseURL:NODE_BASE_URL,
  timeout:TIME_OUT,
  interceptors:{
    requestInterceptor:(config:any)=>{
      // console.log('我是当前实例的请求拦截');
      let token = localCache.getCache('token_self')
      if (token) {
        if(token.indexOf('"')!==-1)
          {
             const reg1 = new RegExp('"', 'g');
            token = token.replace(reg1, '');
          }
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },

    responseInterceptor:(config)=>{
      // console.log('我是当前实例的响应拦截');
      return config.data
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})
export default ycx_Request
