import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { register } from '@/utils/IM/REGISTER/register.js'
import { data_state } from './type'
import axios from 'axios'
import Cache from '@/utils/cache'
import { getPageList, editPageData } from '@/service/modules/nodeSystems'
import { ElMessage } from 'element-plus'

// let baseUrl = "https://tts.fsycxkj.com";
let baseUrl = process.env.VUE_APP_NODE_BASE_URL
// let baseUrl = 'https://node.nsyijiefang.com'
const dataModule: Module<data_state, IRootState> = {
  namespaced: true,
  state() {
    return {
      data_List: [],
      data_Count: 0, //数据条数
      post_List:[],
      post_Count:0,
      group_info: {},
      group_id: '', //群的ID
      show_box: false,
      show_group: false,
      markdown_info:"",
    }
  },
  mutations: {
    change_data_List(state, data_List: any[]) {
      state.data_List = data_List
    },
    change_data_Count(state, data_Count: number) {
      state.data_Count = data_Count
    },
    change_post_List(state, post_List: any[]) {
      state.post_List = post_List
    },
    change_post_Count(state, post_Count: number) {
      state.post_Count = post_Count
    },
    change_group_id(state, group_id: any) {
      state.group_id = group_id
      // console.log(state,group_id,'群的编号为')
    },
    change_group_info(state, group_info: any) {
      state.group_info = group_info
    },
    change_show_box(state, show_box: any) {
      state.show_box = show_box
    },
    change_show_group(state, show_group: any) {
      state.show_group = show_group
    },
    change_markdown_info(state,markdown_info:any){
      state.markdown_info = markdown_info
    }
  },
  getters: {
    pageListData(state) {
      return () => {
        return (state as any)[`data_List`] //选择对应数据
      }
    },
    pageListCount(state) {
      return () => {
        return (state as any)[`data_Count`] //选择对应数据条数
      }
    },
    postListData(state) {
      return () => {
        return (state as any)[`post_List`] //选择对应数据
      }
    },
    postListCount(state) {
      return () => {
        return (state as any)[`post_Count`] //选择对应数据条数
      }
    },
    pageGetApplyInfo() {
      return async (queryInfo: any) => {
        let user_id = Cache.getCache('im_userId')
        let dept_id = Cache.getCache('im_deptId')
        const pageResult: any = await axios.get(
          baseUrl + '/custom/get_group_ifno/imApproval',
          {
            params: {
              user_id: user_id,
              dept_id: dept_id
            }
          }
        )
        console.log(pageResult, '看看请求结果')
        let { data } = pageResult
        if (data.code == 200) {
          return data.data
        } else {
          return null
        }
      }
    },
    pageGetGroupInfo() {
      return async (queryInfo: any) => {
        // let user_id = Cache.getCache('im_userId')
        let dept_id = Cache.getCache('im_deptId')
        const pageResult: any = await axios.get(
          baseUrl + '/custom/get_group_ifno/imCommunityGroup',
          {
            params: {
              dept_id: dept_id
            }
          }
        )
        console.log(pageResult, '看看请求结果')
        let { data } = pageResult
        if (data.code == 200) {
          return data.data
        } else {
          return null
        }
      }
    }
  },
  actions: {
    async changeGroupShow({ commit }, payload: any) {
      commit('change_show_box', payload)
    },
    async changeGroupShowInfo({ commit }, payload: any) {
      commit('change_show_group', payload)
    },
    async getPageListAction({ commit }, payload: any) {
      // // 1.获取pageUrl
      let pageUrl = '/custom/get_quest_ifno/imQuestion'
      let dept_id = Cache.getCache("im_deptId");

      const pageResult = await getPageList(pageUrl, { ...payload.queryInfo,dept_id})
      console.log(pageResult,'看看当前页面的问卷数据',{...payload.queryInfo});
      // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
      // // 3.将数据存储到state中
      const { data, totalRecord } = pageResult.data
      commit('change_data_List', data)
      commit('change_data_Count', totalRecord)
    },
    async getPostListAction({ commit }, payload: any) {
      // // 1.获取pageUrl
      let pageUrl = '/custom/get_post_ifno/imPost'
      if(!payload.queryInfo)
        {
          payload.queryInfo = {}
        }
      payload.queryInfo.type = "问卷调查"
      const pageResult = await getPageList(pageUrl, { ...payload.queryInfo})
      console.log(pageResult,'看看当前页面的问卷数据',{...payload.queryInfo});
      // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
      // // 3.将数据存储到state中
      const { data, totalRecord } = pageResult.data
      commit('change_post_List', data)
      commit('change_post_Count', totalRecord)
    },
    async getMarkDownInfo({ commit }, payload: any) {
      // // 1.获取pageUrl
      let pageUrl = '/custom/get_post_ifno/imPost'
      let {post_id} = payload.queryInfo
      const pageResult = await getPageList(pageUrl, { post_id})

      let add_url = '/custom/add_consult_info/imCustomData'
      await editPageData(add_url, { ...payload.queryInfo })

      // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
      // // 3.将数据存储到state中
      const { data, totalRecord } = pageResult.data
      console.log(data,'看看所获取的文章信息')
      let { post_detail,post_title } = data[0]
      document.title = post_title
      commit('change_markdown_info', post_detail)
    },
    async createPostDataAction({ dispatch,commit }, payload: any) {
      // 1.创建数据的请求
      console.log(payload, '看看需要添加的内容')
      let pageUrl = '/custom/add_post_ifno/imPost'
      commit('change_show_box', false)
      await editPageData(pageUrl, payload)
      // 2.请求最新的数据
      dispatch('getPostListAction', {
        queryInfo: {
          type:"问卷调查"
        }
      })
    },
    async sendPostDataAction({ dispatch,commit }, payload: any) {
      // 1.创建数据的请求
      console.log(payload, '看看需要添加的内容')
      let pageUrl = '/custom/send_group_info/imCustomData'
      commit('change_show_box', false)
      await editPageData(pageUrl, payload)
    },
    async createPageDataAction({ dispatch,commit }, payload: any) {
      // 1.创建数据的请求
      console.log(payload, '看看需要添加的内容')
      let pageUrl = '/custom/add_quest_ifno/imQuestion'
      commit('change_show_box', false)
      await editPageData(pageUrl, payload)
      // 2.请求最新的数据
      dispatch('getPageListAction', {
        queryInfo: {}
      })
    },
    async editPageDataAction({ dispatch,commit }, payload: any) {
      // 1.编辑数据的请求
      console.log(payload, '看看需要修改的内容')
      // delete payload.type
      let pageUrl = '/custom/edit_quest_ifno/imQuestion'
      await editPageData(pageUrl, payload)
      let add_url = "/custom/add_post_info/imCustomData"
      let add_option = {}
      add_option["dept_id"] = payload.dept_id
      add_option["dept_name"] = payload.dept_name
      add_option["group_id"] = payload.group_id
      add_option["group_name"] = payload.group_name
      add_option["post_id"] = payload.post_id
      add_option["post_title"] = payload.post_title
      add_option["read_count"] = payload.read_count
      add_option["attend_count"] = payload.attend_count
      add_option["option_real"] = payload.option_real
      add_option["type"] = "管理员修改"
      await editPageData(add_url, add_option)

      commit('change_show_box', false)
      // 2.请求最新的数据
      // dispatch('getPageListAction', {
      //   queryInfo: {
      //     post_id:payload.post_id
      //   }
      // })
    },
    async editPostDataAction({ dispatch,commit }, payload: any) {
      // 1.编辑数据的请求
      console.log(payload, '看看需要修改的内容')
      let pageUrl = '/custom/edit_post_ifno/imPost'
      await editPageData(pageUrl, payload)
      commit('change_show_box', false)
      // 2.请求最新的数据
      dispatch('getPostListAction', {
        queryInfo: {
          type:"问卷调查"
        }
      })
    },
    async addPageDataAction({ dispatch }, payload: any) {},
    async deletePageDataAction({ dispatch }, payload: any) {
      // 1.获取pageName和id
      // pageName -> /users
      // id -> /users/id
      const pageUrl = `/custom/del_quest_all_ifno/imPost`
      // 2.调用删除网络请求
      await editPageData(pageUrl, payload)

      // 3.重新请求最新的数据
      dispatch('getPostListAction', {
        queryInfo: {
          type:"问卷调查"
        }
      })
    },
    async deletePostDataAction({ dispatch }, payload: any) {
      // 1.获取pageName和id
      // pageName -> /users
      // id -> /users/id
      const pageUrl = `/custom/del_post_ifno/imPost`
      // 2.调用删除网络请求
      await editPageData(pageUrl, payload)

      // 3.重新请求最新的数据
      dispatch('getPostListAction', {
        queryInfo: {
          type:"问卷调查"
        }
      })
    }
  }
}

export default dataModule
