import { createApp } from "vue";
import App from "./App.vue";
import router from './router'
import store from './store'
import { setupStore } from "./store";
import '@/assets/icon_font/iconfont.css'
import '@/assets/css/global.css'
// import { registerApp } from './global'//按需引入Element-plus
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import '@/global/global.css'

let app = createApp(App);

app.config.globalProperties.$myGlobalMethod = function () {
  console.log('This is a global method!');
};

app.use(ElementPlus, {
  locale: zhCn,
})

app.use(mavonEditor)
// registerApp(app)//注册按需导入的组件需引入app
// app.use(registerApp)//安装插件的写法

app.use(store)
setupStore()
app.use(router)
app.mount("#app");

