import { createStore, Store, useStore as useVuexStore  } from 'vuex';

import { IRootState, IStoreType } from './types';
import login from './login';


import { modules } from './modules';

const store = createStore<IRootState>({
  state() {
    return {
      storeInfo: '',
      name: '',
      age: 0,
      entireDepartment: [],
      entireRole: [],
      entireMenu: []
    };
  },
  getters: {},
  mutations: {
    changeEntireDepartment(state, list) {
      state.entireDepartment = list
    },
    changeEntireRole(state, list) {
      state.entireRole = list
    },
    changeEntireMenu(state, list) {
      state.entireMenu = list
    }
  },
  actions: {
    async getInitialDataAction({ commit }) {
      const departmentList = {}
      commit('changeEntireDepartment', departmentList)
      // commit('changeEntireRole', roleList)
      // commit('changeEntireMenu', menuList)
    }
  },
  modules:{...modules,login}

});
export function setupStore() {
  store.dispatch('login/accountLoginAction')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store;
