import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { register } from '@/utils/IM/REGISTER/register.js'
import { data_state } from './type'
import axios from 'axios'
import Cache from '@/utils/cache'
import { getPageList, editPageData } from '@/service/modules/nodeSystems'
import { ElMessage } from 'element-plus'
// let baseUrl = "https://tts.fsycxkj.com";
// let baseUrl = 'https://node.nsyijiefang.com'
let baseUrl = process.env.VUE_APP_NODE_BASE_URL
const dataModule: Module<data_state, IRootState> = {
  namespaced: true,
  state() {
    return {
      data_List: [],
      data_Count: 0, //数据条数
      group_info: {},
      group_id: '', //群的ID
      dept_id:"",//社区ID
    }
  },
  mutations: {
    change_data_List(state, data_List: any[]) {
      state.data_List = data_List
    },
    change_data_Count(state, data_Count: number) {
      state.data_Count = data_Count
    },
    change_group_id(state, group_id: any) {
      state.group_id = group_id
      // console.log(state,group_id,'群的编号为')
    },
    change_group_info(state, group_info: any) {
      state.group_info = group_info
    },
    change_dept_id(state, dept_id: any) {
      state.dept_id = dept_id
    },
  },
  getters: {
    pageListData(state) {
      return () => {
        return (state as any)[`data_List`] //选择对应数据
      }
    },
    pageListCount(state) {
      return () => {
        return (state as any)[`data_Count`] //选择对应数据条数
      }
    },
    postListData(state) {
      return () => {
        return (state as any)[`post_List`] //选择对应数据
      }
    },
    postListCount(state) {
      return () => {
        return (state as any)[`post_Count`] //选择对应数据条数
      }
    },
    pageGetApplyInfo() {
      return async (queryInfo: any) => {
        let user_id = Cache.getCache('im_userId')
        let dept_id = Cache.getCache('im_deptId')
        const pageResult: any = await axios.get(
          baseUrl + '/custom/get_group_ifno/imApproval',
          {
            params: {
              user_id: user_id,
              dept_id: dept_id
            }
          }
        )
        console.log(pageResult, '看看请求结果')
        let { data } = pageResult
        if (data.code == 200) {
          return data.data
        } else {
          return null
        }
      }
    },
    pageGetGroupInfo() {
      return async (queryInfo: any) => {
        // let user_id = Cache.getCache('im_userId')
        let dept_id = Cache.getCache('im_deptId')
        const pageResult: any = await axios.get(
          baseUrl + '/custom/get_group_ifno/imCommunityGroup',
          {
            params: {
              dept_id: dept_id
            }
          }
        )
        console.log(pageResult, '看看请求结果')
        let { data } = pageResult
        if (data.code == 200) {
          return data.data
        } else {
          return null
        }
      }
    }
  },
  actions: {
    async getPageListAction({ commit }, payload: any) {
      console.log(payload,'看看需要查询的咨询的数据')
      // // 1.获取pageUrl
      let dept_id = Cache.getCache("im_deptId");
      let pageUrl = '/custom/get_group_ifno/imCommunityGroup'
      let pageSize = 500
      const pageResult = await getPageList(pageUrl, { ...payload.queryInfo,dept_id,pageSize })
      // console.log(pageResult,'看看当前页面的问卷数据');
      // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
      // // 3.将数据存储到state中
      const { data, totalRecord } = pageResult.data
      commit('change_data_List', data)
      commit('change_data_Count', totalRecord)
    },
    async createPageDataAction({ dispatch,commit }, payload: any) {
      // 1.创建数据的请求
      console.log(payload, '看看需要添加的内容')
      let pageUrl = '/custom/add_group_ifno/imCommunityGroup'
      commit('change_show_box', false)
      await editPageData(pageUrl, payload)
      // 2.请求最新的数据
      dispatch('getPageListAction', {
        queryInfo: {}
      })
    },
    async editPageDataAction({ dispatch,commit }, payload: any) {
      // 1.编辑数据的请求
      console.log(payload, '看看需要修改的内容')
      let pageUrl = '/custom/edit_group_ifno/imCommunityGroup'
      await editPageData(pageUrl, payload)
      commit('change_show_box', false)
      // 2.请求最新的数据
      dispatch('getPageListAction', {
        queryInfo: {}
      })
    },
    async deletePageDataAction({ dispatch }, payload: any) {
      // 1.获取pageName和id
      // pageName -> /users
      // id -> /users/id
      const pageUrl = `/custom/del_group_ifno/imCommunityGroup`
      // 2.调用删除网络请求
      await editPageData(pageUrl, payload)

      // 3.重新请求最新的数据
      dispatch('getPostListAction', {
        queryInfo: {}
      })
    },
  }
}

export default dataModule
